import axios from 'axios';
import {notification} from 'antd';
import Tools from './tools';
import {config} from "./util";

export class services {
    server;

    constructor(host, responseType = "json") {
        this.server = axios.create({
            baseURL: `${host}`,
            headers: {
                'App-Version': '0.1.0',
            },
            timeout: 1000,
            withCredentials: true,
            responseType,
        });

        this.server.defaults.timeout = 120000;

        this.server.interceptors.request.use(function (config) {
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        this.server.interceptors.response.use(function (response) {
            if (responseType === "arraybuffer") {
                let contentType = response.headers['content-type'];
                let filename = decodeURI(contentType.substring(contentType.indexOf('filename=') + 9, contentType.length));
                Tools.downloadByBlob(response.data, filename);
            }
            return response;
        }, function (error) {
            let errorMessage;
            if (responseType === "arraybuffer") {
                errorMessage = JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(error.response.data))).message;
            }
            if (error.code === 'ECONNABORTED') {
                notification.warning({
                    key: 'network error',
                    message: '很抱歉',
                    description: '请检查您的网络是否正常..'
                });
            }
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message && typeof error.response.data.message === "string") ?
                    error.response.data.message : (errorMessage || '系统出错了，请联系管理员');
                errorMessage = errorMessage.length > 50 ? '系统出错了，请联系管理员' : errorMessage;
                switch (error.response.status) {
                    case 401:
                        notification.warning({
                            key: 'status-401',
                            message: errorMessage || '系统好像不认识你',
                            description: '您需要重新登录'
                        });
                        break;
                    case 403:
                        notification.warning({
                            key: 'status-403',
                            message: '很抱歉',
                            description: '你没有权限访问此功能'
                        });
                        break;
                    case 400:
                        notification.warning({
                            message: '很抱歉',
                            description: errorMessage
                        });
                        break;
                    case 404:
                        notification.warning({
                            message: '有一点小问题',
                            description: errorMessage
                        });
                        break;
                    default:
                        notification.open({
                            message: '提示',
                            description: errorMessage
                        });
                        break;
                }
            } else
                notification.warning({
                    key: 'network error',
                    message: '很抱歉',
                    description: '请检查您的网络是否正常..'
                });
            return Promise.reject(error);
        });
    }

    post(path, body, fileList, fileKey = 'files') {
        body = Tools.deleteEmptyOption(body);
        let data = body;
        let headers = {};
        if (fileList && (fileList instanceof Array)) {
            headers = {'Content-Type': 'multipart/form-data'};
            data = new window.FormData();
            this.setFormData(data, body);
            fileList.forEach(file => data.append(fileKey, file));
        }
        return this.request("post", path, data, {headers});
    }

    request(method, path, ...args) {
        return this.server[method](path, ...args)
    }

    get(path, params, options) {
        params = Tools.deleteEmptyOption(params);
        return this.request('get', path, Object.assign({}, {params}, options));
    }

    delete(path, params, options) {
        params = Tools.deleteEmptyOption(params);
        return this.request('delete', path, Object.assign({}, {params}, options));
    }

    /**
     * 设置嵌套formData
     */
    setFormData(formData, params, prefix = "") {
        prefix = prefix ? prefix + "." : '';
        for (const key in params) {
            if ({}.hasOwnProperty.call(params, key)) {
                if (params[key] && (params[key] instanceof Array)) {
                    params[key].forEach((item, index) => {
                        if (typeof item === 'object') {
                            this.setFormData(formData, item, `${prefix}${key}[${index}]`);
                        } else
                            formData.append(`${prefix}${key}[${index}]`, item);
                    });
                } else if (typeof params[key] === 'object') {
                    this.setFormData(formData, params[key], `${prefix}${key}`);
                } else
                    formData.append(`${prefix}${key}`, params[key]);
            }
        }
    }
}

export const baseServices = new services(config.baseURL);